import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Main from './compoenent/main/Main';
import About from './compoenent/About/About';
import Faq from './compoenent/Faq/Faq';
import CustomCase from './compoenent/customCase/CustomCase';
import './global.scss';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/about" element={<About />} />
        <Route path="/faqs" element={<Faq />} />
        <Route path="/customcase" element={<CustomCase />} />
      </Routes>
    </Router>
  );
}

export default App;
