import React, { useEffect, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import StaticHeader from '../staticHeader/StaticHeader';
import Selector from '../dropdown/Selector';
import './main.scss';

function Main() {
  const [theme, setTheme] = useState('dark');
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    body.className = theme;
    const handleResize = () => {
      if (window.innerWidth > 768 && isNavExpanded) {
        setIsNavExpanded(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isNavExpanded, theme]);
  
  const navClass = `nav-bar ${theme} ${isNavExpanded ? 'expanded' : ''}`;
  const navRef = useRef();
  
  useEffect(() => {
    // Function to collapse the nav with drop-up animation
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target) && isNavExpanded) {
        // Trigger drop-up animation
        setTriggerDropup(true);
        setTimeout(() => {
          setIsNavExpanded(false);
          setTriggerDropup(false);
        }, 500); // Duration of the drop-up animation
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isNavExpanded]);
  
  const [triggerDropup, setTriggerDropup] = useState(false);
  const navLinksClass = `nav-links ${isNavExpanded ? 'expanded' : ''} ${triggerDropup ? 'dropup' : ''}`;
  const toggleNav = () => {
    if (isNavExpanded) {
      // Trigger dropup animation before collapsing the menu
      setTriggerDropup(true);
      setTimeout(() => {
        setIsNavExpanded(false);
        setTriggerDropup(false);
      }, 500); // Set timeout duration same as animation
    } else {
      setIsNavExpanded(true);
    }
  };

  return (
    <>
      <div className={navClass} ref={navRef}>
        <div className={`${navLinksClass} ${isNavExpanded ? (theme === 'dark' ? 'navlinks-dark' : 'navlinks-light') : ''}`}>
          <NavLink to="/" className={({ isActive }) => isActive ? 'active' : ''}>
            <span className={`navtext ${theme === 'dark' ? 'navtext-dark' : 'navtext-light'} url`}>Home</span>
          </NavLink>
          <NavLink to="/customcase" className={({ isActive }) => isActive ? 'active' : ''}>
            <span className={`navtext ${theme === 'dark' ? 'navtext-dark' : 'navtext-light'} url`}>Custom Case</span>
          </NavLink>
          <NavLink to="/about" className={({ isActive }) => isActive ? 'active' : ''}>
            <span className={`navtext ${theme === 'dark' ? 'navtext-dark' : 'navtext-light'} url`}>About</span>
          </NavLink>
          <NavLink to="/faqs" className={({ isActive }) => isActive ? 'active' : ''}>
            <span className={`navtext ${theme === 'dark' ? 'navtext-dark' : 'navtext-light'} url`}>FAQs</span>
          </NavLink>
        </div>

        <div className="buttons">
          <a
            href="https://forms.gle/NRUu8ixYXJGbomd99"
            target="_blank"
            rel="noreferrer"
          >
            <button className={`feedbackbtn ${theme === 'dark' ? 'btndark' : 'btnlight'}`}>
                Feedback
            </button>
          </a>
          <a
            href="https://forms.gle/YzYpHZhp9c7J1hBm8"
            target="_blank"
            rel="noreferrer"
          >
            <button className={`feedbackbtn ${theme === 'dark' ? 'btndark' : 'btnlight'}`}>
                Contribute Cases
            </button>
          </a>
          <a
            href="https://www.paypal.com/donate/?business=9GTMS4VJRWLAN&no_recurring=0&item_name=OSCE-GPT+is+free+to+help+healthcare+trainees+improve+patient+care.+Any+donation+would+help+keep+this+site+running+for+them.&currency_code=CAD"
            target="_blank"
            rel="noreferrer">
              <button className={`feedbackbtn ${theme === 'dark' ? 'btndark' : 'btnlight'}`}>
                Donate
              </button>
          </a>
        </div>

        <div className="hamburger" onClick={toggleNav}>
          <div className="hamburger-icon">&#9776;</div>
        </div>
      </div>

      <div className="container">
        <StaticHeader theme={theme} />
        <Selector theme={theme} setTheme={setTheme} />
      </div>
    </>
  );
}

export default Main;
