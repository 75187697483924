import React, { useEffect, useState, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { NavLink } from 'react-router-dom';
import './Faq.scss';

// collapsable menu
const Collapsible = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="collapsible-container">
      <button className="collapsible" onClick={() => setIsOpen(!isOpen)}>
        {title} <span className="collapsible-icon">{isOpen ? '–' : '+'}</span>
      </button>
      <div className={`content ${isOpen ? 'open' : ''}`}>{isOpen && children}</div>
    </div>
  );
};

function Faq() {
  const [theme] = useState('dark');
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    body.className = theme;
    const handleResize = () => {
      if (window.innerWidth > 768 && isNavExpanded) {
        setIsNavExpanded(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isNavExpanded, theme]);
  
  const navClass = `nav-bar ${theme} ${isNavExpanded ? 'expanded' : ''}`;
  const navRef = useRef();
  
  useEffect(() => {
    // Function to collapse the nav with drop-up animation
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target) && isNavExpanded) {
        // Trigger drop-up animation
        setTriggerDropup(true);
        setTimeout(() => {
          setIsNavExpanded(false);
          setTriggerDropup(false);
        }, 500); // Duration of the drop-up animation
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isNavExpanded]);
  
  const [triggerDropup, setTriggerDropup] = useState(false);
  const navLinksClass = `nav-links ${isNavExpanded ? 'expanded' : ''} ${triggerDropup ? 'dropup' : ''}`;
  const toggleNav = () => {
    if (isNavExpanded) {
      // Trigger dropup animation before collapsing the menu
      setTriggerDropup(true);
      setTimeout(() => {
        setIsNavExpanded(false);
        setTriggerDropup(false);
      }, 500); // Set timeout duration same as animation
    } else {
      setIsNavExpanded(true);
    }
  };

  // Share case button
  const [shareButtonText, setShareButtonText] = useState('Share Case');

  const handleShareClick = () => {
    const fullUrl = '<iframe src="https://osceai.ca" allow="microphone" alt="The OSCEai application."></iframe>';

    // Copy to clipboard
    navigator.clipboard.writeText(fullUrl).then(() => {
      setShareButtonText('Link Copied!');

      // Reset button text after 3 seconds
      setTimeout(() => {
        setShareButtonText('Share Case');
      }, 3000);
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  // BEGIN: Footer
  const year = new Date().getFullYear();
  const footerStyle = { position: 'relative', marginBottom: '-100px' };
  // END: Footer

  return (
    <>
      <div className={navClass} ref={navRef}>
        <div className={`${navLinksClass} ${isNavExpanded ? (theme === 'dark' ? 'navlinks-dark' : 'navlinks-light') : ''}`}>
          <NavLink to="/" className={({ isActive }) => isActive ? 'active' : ''}>
            <span className={`navtext ${theme === 'dark' ? 'navtext-dark' : 'navtext-light'} url`}>Home</span>
          </NavLink>
          <NavLink to="/customcase" className={({ isActive }) => isActive ? 'active' : ''}>
            <span className={`navtext ${theme === 'dark' ? 'navtext-dark' : 'navtext-light'} url`}>Custom Case</span>
          </NavLink>
          <NavLink to="/about" className={({ isActive }) => isActive ? 'active' : ''}>
            <span className={`navtext ${theme === 'dark' ? 'navtext-dark' : 'navtext-light'} url`}>About</span>
          </NavLink>
          <NavLink to="/faqs" className={({ isActive }) => isActive ? 'active' : ''}>
            <span className={`navtext ${theme === 'dark' ? 'navtext-dark' : 'navtext-light'} url`}>FAQs</span>
          </NavLink>
        </div>

        <div className="buttons">
          <a
            href="https://forms.gle/NRUu8ixYXJGbomd99"
            target="_blank"
            rel="noreferrer"
          >
            <button className={`feedbackbtn ${theme === 'dark' ? 'btndark' : 'btnlight'}`}>
                Feedback
            </button>
          </a>
          <a
            href="https://forms.gle/YzYpHZhp9c7J1hBm8"
            target="_blank"
            rel="noreferrer"
          >
            <button className={`feedbackbtn ${theme === 'dark' ? 'btndark' : 'btnlight'}`}>
                Contribute Cases
            </button>
          </a>
          <a
            href="https://www.paypal.com/donate/?business=9GTMS4VJRWLAN&no_recurring=0&item_name=OSCE-GPT+is+free+to+help+healthcare+trainees+improve+patient+care.+Any+donation+would+help+keep+this+site+running+for+them.&currency_code=CAD"
            target="_blank"
            rel="noreferrer">
              <button className={`feedbackbtn ${theme === 'dark' ? 'btndark' : 'btnlight'}`}>
                Donate
              </button>
          </a>
        </div>

        <div className="hamburger" onClick={toggleNav}>
          <div className="hamburger-icon">&#9776;</div>
        </div>
      </div>

      <div className="container">

        <div className="page-div">
          <div className="about-title">Frequently Asked Questions</div>

          <div className="app-description">
            <h2 className='top-spacer'>General Questions</h2>
            <Collapsible title="How do I use OSCEai?">
              <p>This platform is designed to help premed students, medical students, resident physicians, and allied professionals practice clinical cases, interviews (CaRMS, MMI), and more. Follow these steps to navigate and use the app:</p>

              <h3>1. Choosing a Case</h3>
              <ul>
                <li>Select a category: Choose a category from the dropdown menu. You can search for a specific category by typing.</li>
                <li>Select a case: After selecting a category, choose a specific case to practice from the subsequent dropdown menu.</li>
                <li>Select a difficulty: After you select the case, a description of what "easy", "medium", and "hard" entails will appear in the case description.</li>
              </ul>

              <h3>2. Interacting with the Case</h3>
              <ul>
                <li>Read the Case Preamble to understand the context.</li>
                <li>Click on images to enlarge and hide or unhide them as needed.</li>
                <li>Interact with the case by typing or speaking. Use the "Switch to Typing/Talking Mode" button to toggle between modes.</li>
                <li>For typing, enter your responses in the text box and press Enter or click the send icon. For speaking, click "Start" to record and "Stop" to end.</li>
              </ul>

              <h3>3. Navigating Through the Case</h3>
              <ul>
                <li>Click "Request Findings" to request physical exam and investigation results. If a result does not exist, then the app will say that it does not exist.</li>
                <li>Click "Share My Plan" to share your management plan for the case.</li>
                <li>Click "Case Questions" for multiple choice questions on the case (this is only present in select cases).</li>
                <li>Click "End Conversation" to complete the case.</li>
              </ul>

              <h3>4. Getting Feedback and Resources</h3>
              <ul>
                <li>You may continue speaking or typing to for follow-up questions/discussion.</li>
                <li>Depending on the case, you can request specific feedback or resources like "Get Feedback," "Get Approach," or "SOAP Note."</li>
                <li>Download options: After completing a case, you can download the transcript or audio for review.</li>
              </ul>

              <h3>5. Additional Features</h3>
              <ul>
                <li>For some cases, there will be case questions, which you can open by clicking the "Case Questions" button located next to the "Get Hint" button.</li>
                <li>Toggle between light and dark themes and adjust the speech speed using the switches provided.</li>
                <li>A timer is available to track the duration of the interaction. You can pause, resume, or reset it as needed.</li>
              </ul>

              <p>Please see the tutorial video below for an example of how to use this app. Please enjoy OSCEai!</p>

              {/* Tutorial Video */}
              <div className="video-container">
                <video width="100%" controls>
                  <source src="tutorial/oscegpt.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </Collapsible>

            <Collapsible title="Can I play a custom case?">
              <p>Yes. You have two options to do so:</p>

              <h3>1. Contributing a Case</h3>
              <p><em>This option is appropriate for those who wish to have cases free and accesssible for everyone. You will receive attribution if you choose to do so. Full app functionality including image uploads are possible with this option.</em></p>
              <ul>
                <li>Click the "Contribute Cases" button at the very top of the app screen.</li>
                <li>Once you will the form, the creators will upload them. All of these cases will be free and accessible to anyone on this website. Your name and/or affiliation will be included on this website as a contributor if you choose to submit it in the form.</li>
                <li>By submitting a case to OSCEai, you are agreeing that the full contents of the case can be freely shared, distributed, modified, and licensed in any way.</li>
              </ul>

              <h3>2. Custom Case</h3>
              <p><em>This option is appropriate for those who wish to test out their cases before submitting or those with proprietary information. No information is saved on this website when you use this option. Image uploads are not possible in this option as OSCEai does not store any submitted information on our server (i.e., sharing cases would not share the images).</em></p>
              <ul>
                <li>Click on the "Custom Case" page at the top of this screen.</li>
                <li>Fill the on page form. Once you submit it with the mandatory fields filled, you can play the case.</li>
                <li>You can share the case once the form is submitted by clicking the "Share Case" button.</li>
              </ul>
            </Collapsible>

            <Collapsible title="Is this app free?">
              <p>Yes. The app is currently funded out of pocket by the creators; it costs around $1,000/year to keep this app running and free for all. If you find the app helpful, please consider donating (see the "Donate" button at the top of this page).</p>
            </Collapsible>

            <Collapsible title="Is my data saved?">
              <p>No. All user inputs exist only for the current session and are deleted after you leave or refresh this website.</p>
            </Collapsible>

            <Collapsible title="Does this app require a login?">
              <p>No login is required.</p>
            </Collapsible>

            <Collapsible title="My microphone isn't working. How do I fix this?">
              <h3>Ensure you have microphone access enabled on your browser.</h3>
              <img className="mobile-photo" src="tutorial/microphone.png" alt="Enable microphone access on OSCEai" />
              <h3>If you are using a mac, ensure that your computer has microphone permissions enabled in System Settings. You can enable it by going to Privacy & Security &gt; Microphone &gt; Enable the setting for your browser.</h3>
            </Collapsible>

            <h2 className='top-spacer'>App Feedback Functionality</h2>

            <Collapsible title="How is feedback generated?">
              <h3>Patient Interactions</h3>
              <p>The interaction between the patient and user is assessed using a research-validated rubric called the <a href="https://journals.lww.com/academicmedicine/fulltext/2003/08000/marrying_content_and_process_in_clinical_method.11.aspx" target="_blank" rel="noreferrer">Calgary-Cambridge guide for medical interviews.</a> For more details, see these publications:</p>
              
              <p>1. Kurtz SM, Silverman JD. The Calgary-Cambridge Referenced Observation Guides: an aid to defining the curriculum and organizing the teaching in communication training programmes. Med Educ. 1996 Mar;30(2):83-9. doi: <a href="https://asmepublications.onlinelibrary.wiley.com/doi/abs/10.1111/j.1365-2923.1996.tb00724.x?sid=nlm%3Apubmed" target="_blank" rel="noreferrer">10.1111/j.1365-2923.1996.tb00724.x</a>. PMID: <a href="https://pubmed.ncbi.nlm.nih.gov/8736242/" target="_blank" rel="noreferrer">8736242</a>.</p>
              
              <p>2. Kurtz S, Silverman J, Benson J, Draper J. Marrying content and process in clinical method teaching: enhancing the Calgary-Cambridge guides. Acad Med. 2003 Aug;78(8):802-9. doi: <a href="https://journals.lww.com/academicmedicine/fulltext/2003/08000/marrying_content_and_process_in_clinical_method.11.aspx" target="_blank" rel="noreferrer">10.1097/00001888-200308000-00011</a>. PMID: <a href="https://pubmed.ncbi.nlm.nih.gov/12915371/" target="_blank" rel="noreferrer">12915371</a>.</p>

              <h3 className='commonPara'>CaRMS Interview Practice</h3>
              <p>The interaction between the interviewer and user is assessed using a rubric from the <a href="https://cumming.ucalgary.ca/student-advocacy-wellness-hub/home" target="_blank" rel="noreferrer">Student Wellness and Advising Office at the University of Calgary</a> developed by previous residency program directors with tips included from medical students and residents.</p>
            </Collapsible>

            <Collapsible title="How are my answers graded?">
              <p>Some cases (but not all) have rubrics built into them. The conversation is assessed to see if each of the items in the rubric are met and provides a numerical score. Some rubrics provide a global assessment of user performance and determines pass/fail based on average user performance.</p>
            </Collapsible>

            <Collapsible title="How are the SOAP notes and patient presentations generated?">
              <p>SOAP notes and patient presentations for each case is generated based on the conversation using a modified SOAP note model called F-SOAP (frame, story, objective data, assessment, plan), developed by Anthony Seto, MD, CCFP(EM) and Katie Lin, MD, FRCPC, MPH. See their poster presentation from the <a href="https://cumming.ucalgary.ca/office/ohmes/what-we-do/events/ohmes-events/symposium-2021-posters" target="_blank" rel="noreferrer">2021 Health & Medical Education Scholarship Symposium</a> below:</p>

              <a href="https://oscegpt.com/tutorial/f-soap.jpeg" target="_blank" rel="noreferrer"><img className="laptop-photo" src="tutorial/f-soap.jpeg" alt="The F-SOAP model research poster." /></a>
            </Collapsible>

            <h2 className='top-spacer'>Sharing</h2>

            <Collapsible title="How do I share a case?">
              <h3>1. Select a difficulty, case category, and a case as you normally would on the app.</h3>

              <h3 className="commonPara">2. Click "Share Case".</h3>
              <img className="laptop-photo" src="tutorial/share-case.png" alt="The share case button" />

              <h3 className="commonPara">3. The case is now copied to your clipboard!</h3>
            </Collapsible>

            <Collapsible title="How do I embed OSCEai into my website?">
              <h3>OPTION 1. Click the button below to copy the OSCEai iframe without any case preselected to your clipboard.</h3>

              <button onClick={handleShareClick} className={`timer-btn ${theme === 'light' ? 'light' : ''}`}>
                {shareButtonText}
              </button>

              <h3 className="commonPara">OPTION 2. Select a difficulty, case category, and a case as you normally would on the app. Then, click "Share Case". Copy the URL into an iframe with the following elements: src="URL" allow="microphone" alt="The OSCEai application." Replace URL with the URL you copied.
              </h3>
            </Collapsible>

            <Collapsible title="What is the logo for OSCEai?">
              <p>Below is the official logo for OSCEai. You may share this logo freely.</p>
              <a className="photo-holder" href="https://learnmedicine.ca/logo.png" target="_blank" rel="noreferrer">
                <img className="logo-photo" src="logo.png" alt="OSCEai Logo" />
              </a>
            </Collapsible>

            <h2 className='top-spacer'>Downloading the App</h2>

            <Collapsible title="How do I download the app on my mobile device or tablet?">
              <h3>1. Click the share icon at the search bar.</h3>
              <img className="mobile-photo" src="tutorial/mobile-download-1.png" alt="Download on iPhone by clicking the share button" />

              <h3 className="commonPara">2. Click "Add to Home Screen".</h3>
              <img className="mobile-photo" src="tutorial/mobile-download-2.png" alt="Download on iPhone by clicking the Add to Home Screen button" />

              <h3 className="commonPara">3. You now have the OSCEai app on your mobile device!</h3>
              <img className="mobile-photo" src="tutorial/mobile-download-3.png" alt="OSCEai mobile app" />
            </Collapsible>

            <Collapsible title="How do I download the app on my laptop?">
              <h3>1. On Google Chrome, click the download icon at the search bar in the top right.</h3>
              <img className="laptop-photo" src="tutorial/laptop-download-1.png" alt="Download on Google Chrome by clicking the share button" />

              <h3 className="commonPara">2. Click "Install".</h3>
              <img className="laptop-photo" src="tutorial/laptop-download-2.png" alt="Download on Google Chrome by clicking the Add to Home Screen button" />

              <h3 className="commonPara">3. You now have the OSCEai app on your computer!</h3>
              <img className="laptop-photo" src="tutorial/laptop-download-3.png" alt="OSCEai laptop app" />
            </Collapsible>

            <h2 className='top-spacer'>Contributing</h2>

            <Collapsible title="How do I provide feedback?">
              <p>Please click the "Feedback" button at the very top of the app screen (you may have to scroll up).</p>
            </Collapsible>

            <Collapsible title="How do I contribute cases?">
              <p>Please click the "Contribute Cases" button at the very top of the app screen (you may have to scroll up).</p>
            </Collapsible>

            <Collapsible title="How do I donate/support the app?">
              <p>Please click the "Donate" button at the very top of the app screen (you may have to scroll up). The app costs the creators about $1,000/year to keep this app running and free for all. Your donation will help keep this app free and running for all to use the website.</p>
            </Collapsible>

            <Collapsible title="How do I collaborate with OSCEai?">
              <p>Please reach out to Eddie Guo, the creator of OSCEai, via email if you are interested in collaborating with OSCEai: eddie.guo at ucalgary dot ca (replace 'at' with @ and dot with '.').</p>
            </Collapsible>
          </div>
        </div>
      </div>

      <div className="footer" style={footerStyle}>
        <div className="copyright">
          © 2023-{year} Eddie Guo and Mehul Gupta. All rights reserved.
        </div>
      </div>
    </>
  );
}

export default Faq;
