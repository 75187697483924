import React, { useEffect, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { cases } from '../dropdown/cases';
import './About.scss';

function About() {
  const [theme] = useState('dark');
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    body.className = theme;
    const handleResize = () => {
      if (window.innerWidth > 768 && isNavExpanded) {
        setIsNavExpanded(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isNavExpanded, theme]);
  
  const navClass = `nav-bar ${theme} ${isNavExpanded ? 'expanded' : ''}`;
  const navRef = useRef();
  
  useEffect(() => {
    // Function to collapse the nav with drop-up animation
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target) && isNavExpanded) {
        // Trigger drop-up animation
        setTriggerDropup(true);
        setTimeout(() => {
          setIsNavExpanded(false);
          setTriggerDropup(false);
        }, 500); // Duration of the drop-up animation
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isNavExpanded]);
  
  const [triggerDropup, setTriggerDropup] = useState(false);
  const navLinksClass = `nav-links ${isNavExpanded ? 'expanded' : ''} ${triggerDropup ? 'dropup' : ''}`;
  const toggleNav = () => {
    if (isNavExpanded) {
      // Trigger dropup animation before collapsing the menu
      setTriggerDropup(true);
      setTimeout(() => {
        setIsNavExpanded(false);
        setTriggerDropup(false);
      }, 500); // Set timeout duration same as animation
    } else {
      setIsNavExpanded(true);
    }
  };

  useEffect(() => {
    const cloneRowsAfterImagesLoad = () => {
      const rows = document.querySelectorAll('.row');
  
      rows.forEach(row => {
        // Create a wrapper
        const wrapper = document.createElement('div');
        wrapper.classList.add('row-wrapper');
        row.parentNode.insertBefore(wrapper, row);
        wrapper.appendChild(row);
  
        // Clone the row
        const clone = row.cloneNode(true);
        wrapper.appendChild(clone);
  
        // Ensure the browser has rendered the cloned content
        requestAnimationFrame(() => {
          // Request another frame to measure after the clone is rendered
          requestAnimationFrame(() => {
            const totalWidth = wrapper.offsetWidth; // Get the total width including the clone
            const scrollDuration = (totalWidth / row.offsetWidth) * 50; // Adjust base duration as needed
            wrapper.style.animationDuration = `${scrollDuration}s`;
          });
        });
      });
    };
  
    // Clone rows after initial render
    requestAnimationFrame(cloneRowsAfterImagesLoad);
  }, []);

  const isUniqueEntry = (entry, index, self) => {
    return (
      self.findIndex(
        (item) =>
          item.author === entry.author &&
          item.author_link === entry.author_link &&
          item.affiliation === entry.affiliation
      ) === index
    );
  };

  // Extract unique authors, affiliations, and author_links from the cases object
  const authorsData = Object.values(cases)
    .map((caseInfo) => {
      const author = caseInfo[0].author;
      const authorLink = caseInfo[0].author_link || ''; // Handle missing author links
      const affiliation = caseInfo[0].affiliation;

      if (author && author !== 'Eddie Guo') {
        return { author, authorLink, affiliation };
      }

      return null;
    })
    .filter((entry) => entry !== null)
    .filter(isUniqueEntry);

  // collapsable bio
  const CollapsibleBio = ({ name, link, children }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    return (
      <div className="collapsible-bio-container">
        <div className="collapsible-bio-header" onClick={() => setIsOpen(!isOpen)}>
          <h2 className="collapsible-bio-name">
            {name}
            <a href={link} target="_blank" rel="noreferrer" className="linkedin-logo">
              <i className="fab fa-linkedin"></i>
            </a>
          </h2>
          <span className="collapsible-bio-icon">{isOpen ? '−' : '+'}</span>
        </div>
        <div className={`collapsible-bio-content ${isOpen ? 'open' : ''}`}>
          {children}
        </div>
      </div>
    );
  };

  // BEGIN: Footer
  const year = new Date().getFullYear();
  const footerStyle = { position: 'relative', marginBottom: '-100px' };
  // END: Footer

  return (
    <>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css"></link>

      <div className={navClass} ref={navRef}>
        <div className={`${navLinksClass} ${isNavExpanded ? (theme === 'dark' ? 'navlinks-dark' : 'navlinks-light') : ''}`}>
          <NavLink to="/" className={({ isActive }) => isActive ? 'active' : ''}>
            <span className={`navtext ${theme === 'dark' ? 'navtext-dark' : 'navtext-light'} url`}>Home</span>
          </NavLink>
          <NavLink to="/customcase" className={({ isActive }) => isActive ? 'active' : ''}>
            <span className={`navtext ${theme === 'dark' ? 'navtext-dark' : 'navtext-light'} url`}>Custom Case</span>
          </NavLink>
          <NavLink to="/about" className={({ isActive }) => isActive ? 'active' : ''}>
            <span className={`navtext ${theme === 'dark' ? 'navtext-dark' : 'navtext-light'} url`}>About</span>
          </NavLink>
          <NavLink to="/faqs" className={({ isActive }) => isActive ? 'active' : ''}>
            <span className={`navtext ${theme === 'dark' ? 'navtext-dark' : 'navtext-light'} url`}>FAQs</span>
          </NavLink>
        </div>

        <div className="buttons">
          <a
            href="https://forms.gle/NRUu8ixYXJGbomd99"
            target="_blank"
            rel="noreferrer"
          >
            <button className={`feedbackbtn ${theme === 'dark' ? 'btndark' : 'btnlight'}`}>
                Feedback
            </button>
          </a>
          <a
            href="https://forms.gle/YzYpHZhp9c7J1hBm8"
            target="_blank"
            rel="noreferrer"
          >
            <button className={`feedbackbtn ${theme === 'dark' ? 'btndark' : 'btnlight'}`}>
                Contribute Cases
            </button>
          </a>
          <a
            href="https://www.paypal.com/donate/?business=9GTMS4VJRWLAN&no_recurring=0&item_name=OSCE-GPT+is+free+to+help+healthcare+trainees+improve+patient+care.+Any+donation+would+help+keep+this+site+running+for+them.&currency_code=CAD"
            target="_blank"
            rel="noreferrer">
              <button className={`feedbackbtn ${theme === 'dark' ? 'btndark' : 'btnlight'}`}>
                Donate
              </button>
          </a>
        </div>

        <div className="hamburger" onClick={toggleNav}>
          <div className="hamburger-icon">&#9776;</div>
        </div>
      </div>

      <div className="container">

        <div className="page-div">
          <div className="about-title">About Us</div>

          {/* About OSCEai */}
          <div className="app-description">
            <h1>About OSCEai</h1>
            <p>
              Welcome to OSCEai, an app designed for healthcare trainees along the entire spectrum of training (premed, med, residency, allied health, etc.). The platform provides immersive and interactive clinical scenarios, mirroring real-life cases and enhancing practical skill development. Trainees interact with simulated cases, receive instant, personalized feedback, and engage in a tailored learning process. Please enjoy OSCEai, where technology meets clinical training.
            </p>
          </div>

          {/* About the Creators */}
          <h1>About the Creators</h1>
          <div className="bio-wall">
            <div className="bio">
              <a className="photo-holder" href="https://eddieguo.ca/" target="_blank" rel="noreferrer">
                <img className="bio-photo" src="/about_images/eddie.jpg" alt="Eddie" />
              </a>
              <CollapsibleBio name="Eddie Guo" className="url" link="https://www.linkedin.com/in/eguo1/" target="_blank" rel="noreferrer">
                Eddie Guo is an MD student at the University of Calgary. Eddie's research include large language models for medicine, machine learning in surgery, medical education, and biomedical engineering. Alongside his studies, Eddie plays an active role in student leadership as the President of the Calgary Medical Students' Association.
              </CollapsibleBio>
            </div>

            <div className="bio">
              <a className="photo-holder" href="https://www.linkedin.com/in/mehul-g-089b57123/" target="_blank" rel="noreferrer">
                <img className="bio-photo" src="/about_images/mehul.jpeg" alt="Mehul" />
              </a>
              <CollapsibleBio name="Mehul Gupta, MD" className="url" link="https://www.linkedin.com/in/mehul-g-089b57123/" target="_blank" rel="noreferrer">
                Dr. Mehul Gupta is an internal medicine resident at the University of Calgary. His research interests are in medical genetics, bioinformatics, as well as the interface between public policy and medical practice. He is also a passionate advocate for mental health, working to create resources aimed at supporting those in need.
              </CollapsibleBio>
            </div>
          </div>

          {/* Research & Collaborations */}
          <div className="app-description">
            <h1>Publications</h1>
            <ul>
              <li>E. Guo, R. Ramchandani, Y. Park, M. Gupta, "OSCEai: Personalized Interactive Learning for Undergraduate Medical Education," <em>Canadian Medical Education Journal</em>, Aug 6, 2024, doi: <a href="https://doi.org/10.36834/cmej.79220" target="_blank" rel="noreferrer">10.36834/cmej.79220</a></li>
              <li>R. Ramchandani, S.G. Biglou, M. Gupta, E. Guo, "Using AI to revolutionize clinical training through OSCEai: a focused exploration of user feedback on otolaryngology and neurology cases," <em>CNSF 2024</em>, Toronto, ON, Canada, May 20-25, 2024, doi: <a href="https://doi.org/10.1017/cjn.2024.176" target="_blank" rel="noreferrer">10.1017/cjn.2024.176</a>.</li>
            </ul>
          </div>
            
          <div className="app-description">
            <h1>Collaborators</h1>
            <h3>Medical Schools</h3>
            <ul>
              <li><a href="https://cumming.ucalgary.ca/" target="_blank" rel="noreferrer">Cumming School of Medicine, University of Calgary</a>: Incorporation into the new <a href="https://cumming.ucalgary.ca/sites/default/files/teams/4/RIME/UME%20Associate%20Dean's%20Message%20-%20RIME%20July%202022.pdf" target="_blank" rel="noreferrer">RIME curriculum</a> to introduce the patient of the week. Students take a history, obtain physical and investigation results, come up with a management plan, and get feedback/documentation.</li>
              <li><a href="https://medschool.healthsci.mcmaster.ca/" target="_blank" rel="noreferrer">Michael G.DeGroote School of Medicine, McMaster University</a>: Research into the effectiveness of this app at creating interactive case-based scenarios for medical students (ongoing).</li>
            </ul>
            
            <h3>Residency Programs</h3>
            <ul>
              <li><a href="https://cumming.ucalgary.ca/departments/dcns/home" target="_blank" rel="noreferrer">Division of Neurosurgery, University of Calgary</a>, <a href="https://surgery.utoronto.ca/division-neurosurgery" target="_blank" rel="noreferrer">Division of Neurosurgery, University of Toronto</a>: Research into the effectiveness of this app at creating interactive case-based scenarios for neurosurgical residents (ongoing).</li>
              <li><a href="https://cumming.ucalgary.ca/departments/medicine/divisionssections/dermatology" target="_blank" rel="noreferrer">Division of Dermatology, University of Calgary</a>, <a href="https://deptmedicine.utoronto.ca/dermatology" target="_blank" rel="noreferrer">Division of Dermatology, University of Toronto</a>: Research into the effectiveness of this app at creating interactive case-based scenarios for dermatology residents (ongoing).</li>
            </ul>

            <h3>Allied Health</h3>
            <ul>
              <li><a href="https://www.tru.ca/nursing.html" target="_blank" rel="noreferrer">Thompson River University</a>: Thompson Rivers University (TRU) utilizes OSCEai to enhance educational experiences for students. By integrating advanced technologies, we provide high-quality learning opportunities that foster essential skills for future healthcare professionals. Our commitment to innovation ensures that students develop critical thinking, psychomotor coordination, communication, and resource management skills, preparing them for success in clinical settings.</li>
              <li><a href="https://www.sait.ca/" target="_blank" rel="noreferrer">Southern Alberta Institute of Technology (SAIT)</a>: Practice cases for respiratory therapy students.</li>
              <li><a href="https://albertanursing.ca/" target="_blank" rel="noreferrer">Alberta Association of Nursies (AAN)</a>: Use of the app for internationally educated nurses to familiarize themselves with patient communications and medical management in English.</li>
              <li>NURS 289 - Inte Nurs Roles & Prac I: Learn, Praxis & Scholarship Practicum Setting (University of Calgary): Used in a lab station to develop relational practice skills with a simulated patient.</li>
            </ul>

            <h3>Organizational Partners</h3>
            <ul>
              <li><a href="https://www.octcases.com/" target="_blank" rel="noreferrer">OCTcases</a>: Bringing interactivity to OCT cases, a medical education training platform based out of the University of Toronto, Department of Ophthalmology and Vision Sciences. OCTcases provides educational cases for interpreting Optical Coherence Tomography (OCT) images through various real life cases.</li>
            </ul>
          </div>

          {/* Case Contributors */}
          <div className="app-description">
            <h1>Case Contributors</h1>
            <p className="commonPara">Our app would not be possible without the support of our contributors providing high-quality clinical cases.</p>
            <ul>
              {authorsData.map((entry, index) => (
                <li key={index}>
                  {entry.authorLink ? (
                    <a href={entry.authorLink} target="_blank" rel="noreferrer">{entry.author}</a>
                  ) : (
                    entry.author
                  )}
                  {entry.affiliation && `, ${entry.affiliation}`}
                </li>
              ))}
            </ul>
          </div>

          {/* Media Coverage */}
          <div className="app-description">
            <h1>Media Coverage</h1>
            <p className="commonPara">Scroll sideways to see the media outlets. Click on the images to read the stories.</p>

            <div className="photo-wall">
              <div className="row">
                <a href="https://www.youtube.com/watch?v=e8d3D5-pBSM" target="_blank" rel="noreferrer">
                  <img src="/news_icons/canadianpress.png" alt="The Canadian Press logo" className="partner-logo" />
                </a>
                <a href="https://agetechworld-co-uk.cdn.ampproject.org/c/s/agetechworld.co.uk/news/new-ai-app-could-hold-key-to-better-older-patient-doctor-communication/?amp=1" target="_blank" rel="noreferrer">
                  <img src="/news_icons/agetechworld.png" alt="AgeTech World logo" className="partner-logo" />
                </a>
                <a href="https://globalnews.ca/news/9978614/new-app-uses-ai-medical-students-practise-interacting-patients/" target="_blank" rel="noreferrer">
                  <img src="/news_icons/globalnews.jpeg" alt="Global News logo" className="partner-logo" />
                </a>
                <a href="https://www.theglobeandmail.com/canada/alberta/article-new-app-uses-ai-to-help-calgary-medical-students-practise-interacting/" target="_blank" rel="noreferrer">
                  <img src="/news_icons/globeandmail.png" alt="The Globe & Mail logo" className="partner-logo" />
                </a>
                <a href="https://www.thestar.com/news/canada/new-app-uses-ai-to-help-calgary-medical-students-practise-interacting-with-patients/article_e24a869c-58f4-58ee-99ea-666e2bc65f27.amp.html" target="_blank" rel="noreferrer">
                  <img src="/news_icons/torontostar.png" alt="Toronto Star logo" className="partner-logo" />
                </a>
                <a href="https://www.cbc.ca/amp/1.6975645" target="_blank" rel="noreferrer">
                  <img src="/news_icons/cbccal.jpeg" alt="CBC Calgary" className="partner-logo" />
                </a>
                <a href="https://cumming.ucalgary.ca/news/ucalgary-students-create-app-help-medical-students-learn-how-talk-patients" target="_blank" rel="noreferrer">
                  <img src="/news_icons/ucalgarynews.png" alt="UCalgary News logo" className="partner-logo" />
                </a>
                <a href="https://calgary.ctvnews.ca/new-app-uses-ai-to-help-calgary-medical-students-practise-interacting-with-patients-1.6573065?utm_source=ground.news&utm_medium=referral" target="_blank" rel="noreferrer">
                  <img src="/news_icons/ctvcalgary.jpeg" alt="CTV Calgary logo" className="partner-logo" />
                </a>
                <a href="https://www.cochraneeagle.ca/alberta-news/new-app-uses-ai-to-help-calgary-medical-students-practise-interacting-with-patients-7583920" target="_blank" rel="noreferrer">
                  <img src="/news_icons/cochraneeagle.jpeg" alt="Cochrane Eagle logo" className="partner-logo" />
                </a>
                <a href="https://www.noovo.info/nouvelle/de-futurs-medecins-formes-avec-une-application.html" target="_blank" rel="noreferrer">
                  <img src="/news_icons/noovoinfo.jpeg" alt="Noovo Info logo" className="partner-logo" />
                </a>
                <a href="https://calgary.citynews.ca/2023/09/22/calgary-medical-student-app/" target="_blank" rel="noreferrer">
                  <img src="/news_icons/citynewscalgary.png" alt="CityNews Calgary logo" className="partner-logo" />
                </a>
                <a href="https://lactualite.com/actualites/un-etudiant-de-calgary-cree-une-application-pour-former-les-futurs-medecins/" target="_blank" rel="noreferrer">
                  <img src="/news_icons/lactualite.png" alt="L'Actualite logo" className="partner-logo" />
                </a>
              </div>
            </div>
          </div>

          {/* Sponsors & Grants */}
          <div className="app-description">
            <h1>Grants</h1>
            <p className="commonPara">The support of these organizations help keep this app running and free for all.</p>

            <div className="bio-wall">
              <div className="logo-bio">
                <a className="logo-holder" href="https://cumming.ucalgary.ca/departments/surgery/surgery" target="_blank" rel="noreferrer">
                  <img src="funder_icons/ucalsurgery.png" alt="UofC Dept of Surgery logo" className="bio-photo" />
                </a>
                <p className="bio-text">
                  <a className="url" href="https://cumming.ucalgary.ca/departments/surgery/surgery" target="_blank" rel="noreferrer">UCalgary Innovation in Surgical Education Grant</a>
                </p>
              </div>

              <div className="logo-bio">
                <a className="logo-holder" href="https://www.su.ucalgary.ca/programs-services/funding-awards/quality-money/" target="_blank" rel="noreferrer">
                  <img src="funder_icons/QMlogo.jpg" alt="UofC SU Quality Money logo" className="bio-photo" />
                </a>
                <p className="bio-text">
                  <a className="url" href="https://www.su.ucalgary.ca/programs-services/funding-awards/quality-money/" target="_blank" rel="noreferrer">UCalgary SU Quality Money Grant</a>
                </p>
              </div>

              <div className="logo-bio">
                <a className="logo-holder" href="https://foundershub.startups.microsoft.com/" target="_blank" rel="noreferrer">
                  <img src="funder_icons/Microsoft-for-Startups.jpg" alt="Microsoft for Startups logo" className="bio-photo" />
                </a>
                <p className="bio-text">
                  <a className="url" href="https://foundershub.startups.microsoft.com/" target="_blank" rel="noreferrer">Microsoft for Startups Founders Hub</a>
                </p>
              </div>

              <div className="logo-bio">
                <a className="logo-holder" href="https://omsa.ca/en/mserg" target="_blank" rel="noreferrer">
                  <img src="funder_icons/mserg.png" alt="OMSA MSERG Logo" className="bio-photo" />
                </a>
                <p className="bio-text">
                  <a className="url" href="https://omsa.ca/en/mserg" target="_blank" rel="noreferrer">OMSA MSERG</a>
                </p>
              </div>

              <div className="logo-bio">
                <a className="logo-holder" href="https://www.cfms.org/what-we-do/finances/student-initiative-grants" target="_blank" rel="noreferrer">
                  <img src="funder_icons/cfms.jpeg" alt="CFMS Logo" className="bio-photo" />
                </a>
                <p className="bio-text">
                  <a className="url" href="https://www.cfms.org/what-we-do/finances/student-initiative-grants" target="_blank" rel="noreferrer">CFMS Student Initiative Grant</a>
                </p>
              </div>

              <div className="logo-bio">
                <a className="logo-holder" href="https://www.calgarymsa.com/" target="_blank" rel="noreferrer">
                  <img src="funder_icons/cmsa.jpeg" alt="CMSA Logo" className="bio-photo" />
                </a>
                <p className="bio-text">
                  <a className="url" href="https://www.calgarymsa.com/" target="_blank" rel="noreferrer">CMSA Club Funding</a>
                </p>
              </div>

              <div className="logo-bio">
                <a className="logo-holder" href="https://openai.com/" target="_blank" rel="noreferrer">
                  <img src="funder_icons/openai.png" alt="OpenAI Logo" className="bio-photo" />
                </a>
                <p className="bio-text">
                  <a className="url" href="https://openai.com/" target="_blank" rel="noreferrer">OpenAI Researcher Access Program</a>
                </p>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div className="footer" style={footerStyle}>
        <div className="copyright">
          © 2023-{year} Eddie Guo and Mehul Gupta. All rights reserved.
        </div>
      </div>
    </>
  );
}

export default About;
