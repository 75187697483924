import React from "react";
import "./header.scss";

function StaticHeader({ theme }) {
  return (
    <>
      <div className='page-div'>
        <h1 className={`h1text ${theme === "light" ? "light" : "dark"}`}>
          OSCEai
        </h1>
        {/* <p className={`tagline ${theme === "light" ? "light" : "dark"}`}>
          Powered by OpenAI Whisper, GPT-4, and text-to-speech.{" "}
        </p> */}
        <p className={`tagline ${theme === "light" ? "light" : "dark"}`}>
          By <a href="https://eddieguo.ca/" target="_blank" rel="noreferrer">Eddie Guo</a> and <a href="https://www.linkedin.com/in/mehul-g-089b57123/" target="_blank" rel="noreferrer">Mehul Gupta, MD</a>
        </p>
      </div>
    </>
  );
}

export default StaticHeader;
